<script setup lang="ts"></script>

<template>
  <div :class="`absolute rounded z-50 mt-2 bg-white border border-[#E3E3E3] drop-shadow right-0`">
    <div class="max-w-52 w-max">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="scss"></style>
