export default {
  welcome: 'Willkommen',
  yes: 'Ja',
  no: 'Nein',
  ra: 'RA',
  m: 'M',
  eu: 'EU',
  loading: 'Laden...',
  recentlyUpdated: 'Kürzlich aktualisiert',
  showAll: 'Alle anzeigen',
  showLess: 'Weniger anzeigen',
  tutorial: {
    dashboard: [
      {
        title: 'Willkommen bei Ihrer neuen Online-Anwendung.',
        description:
          'Ab sofort werden Sie gebeten, diese Anwendung für die GMP+ Risk Management Tools zu verwenden. Dies ist wichtig, da Ihre Nutzung dieser Umgebung uns hilft, eventuelle verbleibende Probleme zu identifizieren. Bitte beachten Sie, dass dies die erste Veröffentlichung ist; daher wird nicht alles zu diesem Zeitpunkt voll funktionsfähig sein. Wir werden Ihre Einarbeitung so einfach wie möglich gestalten, indem wir eine kurze, aber nützliche Demo durch die gesamte Umgebung anbieten. Sie können die Demo mit dem Knopf auf Ihrer rechten Seite starten. ',
      },
      {
        title: 'Feedback',
        description:
          'Haben Sie Feedback für uns zu dieser Anwendung? Durch Klicken auf diese Schaltfläche können Sie uns auf Probleme hinweisen. Sie können diese Schaltfläche auf jeder Seite verwenden.',
      },
      {
        title: 'Schnellzugriffe',
        description:
          'Hier finden Sie Schnellzugriffe auf die GMP+ scheme Dokumente, Risikobewertungen und die GMP+ Company database. Sie können auch die Navigation auf der linken Seite verwenden. Hier finden Sie auch das Early Warning System.',
      },
      {
        title: 'Helpdesk',
        description:
          'Wir sind hier, um Ihnen in jeder erdenklichen Weise zu helfen. Kontaktieren Sie uns einfach über diese Schaltfläche, wenn Sie Fragen haben. Möchten Sie Feedback geben? Bitte verwenden Sie die Feedback-Schaltfläche.',
      },
      {
        title: 'Navigation sperren',
        description: 'Sperren Sie die Navigation hier einfach.',
      },
      {
        title: 'Tour',
        description: 'Starten Sie diese Demo-Tour mit nur einem Klick.',
      },
    ],
    button: {
      next: 'Weiter',
      previous: 'Zurück',
      done: 'Fertig',
    },
  },
  myMonitoring: {
    introText: `<p>Die GMP+ Monitoring database enthält anonymisierte Analyseergebnisse von nach GMP+ zertifizierten
Unternehmen. Dadurch können andere nach GMP+ zertifizierte Unternehmen ihre Risikomanagementsysteme verbessern.</p>
<b>Manuelle Eingabe eines neuen „sample“</b>
<p>Du kannst manuell ein neues Muster eingeben, indem du auf die Schaltfläche "<b>+Neue Probe eingeben</b>" klickst. Folge einfach den Schritten und klicke auf "<b>Erstellen</b>", um das Muster zu übermitteln*.
*Wenn das Labor, mit dem du zusammenarbeitest, nicht im Dropdown-Menü aufgeführt ist, gib bitte seinen Namen in das Feld "Note" ein.</p>
<b>Digitale Übermittlung der Analyseergebnisse</b>
<p>Analysenergebnisse können auch digital in Form einer Excel- oder XML-Datei eingereicht werden. Die Datei kann entweder von dir oder vom Labor erstellt und an die GMP+ Monitoring database übermittelt werden. Damit die Datei korrekt verarbeitet werden kann, muss sie bestimmte Bedingungen erfüllen.</p>
<p>
In beiden Formaten kannst du:
<br />
✔ eine bereits registrierte Probe mit Analyseergebnissen ergänzen oder
<br/>
✔ alle Daten digital einreichen.</p>
<b>Templates und Beispiele</b>
<p>Hier findest du leere Templates und Beispieldateien. Bevor du die Excel-Datei '<b>Empty Template</b>' ausfüllst, empfehlen wir, zuerst die '<b>Example</b>' Datei zu prüfen und deine Datei zu testen, um spätere Probleme zu vermeiden.</p>
<table id="gmp-table" class="border border-collapse">
<tbody class="divide-y">
<tr class="divide-x">
<th></th>
<th>Example</th>
<th>Empty Template</th>
</tr>
<tr class="divide-x">
<td class="font-bold">Excel - Vollständige Einreichung**</td>
<td ><a href="https://www.gmpplus.org/media/fp0fzcas/example-5.xlsx" target="_blank" class="text-primary underline">Example</a></td>
<td><a href="https://www.gmpplus.org/media/vy4l24xz/gmp-excel-volledig-bericht-template.xlsx" target="_blank" class="text-primary underline">Empty Template</a></td>
</tr>
<tr class="divide-x">
<td class="font-bold">Excel - Ergänzung zur registrierten Probe***</td>
<td><a href="https://www.gmpplus.org/media/fp0fzcas/example-5.xlsx" class="text-primary underline" target="_blank">Example</a></td>
<td><a href="https://www.gmpplus.org/media/kpjcto34/gmp-excel-aanvulling-template.xlsx" target="_blank" class="text-primary underline">Empty Template</a></td>
</tr>
<tr class="divide-x">
<td class="font-bold">XML - Vollständige Einreichung</td>
<td></td>
<td><a href="https://www.gmpplus.org/media/nmqpeesu/gmpplus-xml-volledig-bericht-template.xml" target="_blank" class="text-primary underline">Empty Template</a></td>
</tr>
<tr class="divide-x">
<td class="font-bold">XML - Ergänzung zur registrierten Probe</td>
<td></td>
<td><a href="https://www.gmpplus.org/media/cctlz5ee/gmpplus-xml-aanvulling-template.xml" target="_blank" class="text-primary underline">Empty Template</a></td>
</tr>
</tbody>
</table>
<p>** Um ein völlig neues Probenergebnis einzureichen, verwende bitte die Vorlagen "Vollständige Einreichung".</p>
<p>***Um das Ergebnis einer Probe mit dem Status "Geplant" in deiner Überwachungsdatenbank zu übermitteln, verwende die Vorlagen "Ergänzung zur registrierten Probe".</p>
<b>Übermittlung digitaler Analyseergebnisse</b>
<b>Testen Sie Ihre Datei</b>
<p>
Wir empfehlen Ihnen, Ihre Datei vor der Übermittlung immer zu testen. Sie können sie senden an: <a href="mailto:portalmdvalidate{'@'}gmpplus.org" class="text-primary underline">portalmdvalidate{'@'}gmpplus.org</a>****<br />
Sobald Ihr digitaler Beitrag fertig ist, können Sie ihn per E-Mail an folgende Adresse senden: <a href="mailto:monitoringdata{'@'}gmpplus.org" class="text-primary underline">monitoringdata{'@'}gmpplus.org</a>****
</p>
<b>Code-Listen</b>
<p>
Um einen digitalen Antrag zu erstellen, benötigst du verschiedene Codes, u. a. für Laboratorien, Lieferanten/Hersteller, Herkunftsland und unerwünschte Stoffe, die in der GMP+ Monitoring database erfasst sind.<br />
<a href="/portal/risk-management/monitoring-database/code-lists" class="text-primary underline">Hier kannst du die Codelisten einsehen</a>
</p>
<p>
****Wichtig: Bitte keine Fragen oder Kommentare an diese E-Mail-Adressen senden. Sie sind ausschließlich für die Übermittlung digitaler Analyseergebnisse bestimmt. Bei Fragen zur GMP+ Monitoring database wende dich bitte an den Helpdesk.
</p>`,
    aboutMyMonitoring: `GMP+ Monitoring database`,
  },
  nav: {
    profile: {
      tou: 'Nutzungsbedingungen',
      touLink: '/tou/Terms of Use GMP+ Platform - DE.pdf',
      switch: {
        portal: 'Zum Kundenportal',
        management: 'Zum Management',
      },
      settings: 'Einstellungen',
      logout: 'Abmelden',
      language: {
        title: 'Sprache',
        en: 'Englisch',
        de: 'Deutsch',
        nl: 'Niederländisch',
      },
    },
    detailNavBar: {
      issuerId: 'Aussteller-ID',
      myProducts: 'Meine Produkte',
      countryOfOrigin: 'Herkunftsland',
      methods: 'Methoden',
      parameter: 'Parameter',
      producerSupplier: 'Hersteller & Lieferant',
      product: 'Produkt',
      generalInformation: 'Allgemeine Informationen',
      tracingData: 'Rückverfolgungsdaten',
      analysisReport: 'Analysebericht',
      products: 'Produkte',
      processingAids: 'Verarbeitungshilfsstoffe',
      flowcharts: 'Flussdiagramme',
      processSteps: 'Prozessschritte',
      processStep: 'Prozessschritt',
      processLinking: 'Prozessverknüpfung',
      hazards: 'Gefahren',
      feedMaterials: 'Produktliste',
      controlMeasures: 'Kontrollmaßnahmen',
      limits: 'Grenzwerte',
      productInformation: 'Produktinformationen',
      riskAssessments: 'Risikobewertungen',
      documents: 'Dokumente',
      factSheets: 'Fact sheets',
      processingAidGroup: 'Verarbeitungshilfsstoffgruppe',
      hazardInformation: 'Gefahreninformationen',
      undesirableSubstances: 'Unerwünschte Stoffe',
      processingAidsInformation: 'Verarbeitungshilfsstoffinformationen',
      selectLocation: 'Standort auswählen',
      defineStartAndEndDate: 'Start- und Enddatum definieren',
      childHazards: 'Untergefahren',
    },
    menu: {
      myProducts: 'Meine Produkte',
      analysisSets: 'Analyse-Sets',
      certificationBodies: 'Zertifizierungsstellen',
      certifiedCompanies: 'Zertifizierte Unternehmen',
      codeLists: 'Code-Listen',
      controlMeasureGroups: 'Kontrollmaßnahmen-Gruppen',
      controlMeasures: 'Kontrollmaßnahmen',
      dashboard: 'Dashboard',
      digitalSubmission: 'Digitale Einreichung',
      earlyWarningSystem: 'Early Warning System',
      feedSchemeDocuments: 'GMP+ Scheme Dokumente',
      documents: 'Dokumente',
      framework: 'Rahmen',
      gmpCompanyDatabase: 'GMP+ Company database',
      hazards: 'Gefahren',
      laboratories: 'Labor Tåtigkeiten',
      mainMenu: 'Hauptmenü',
      management: 'Management',
      methods: 'Methoden',
      monitoringDatabase: 'Monitoring database',
      monitoringGroups: 'Überwachungsgruppen',
      myGroups: 'Meine Gruppen',
      groupManagement: 'Verwaltung von Gruppenmitgliedern',
      myMonitoring: 'Meine Überwachung',
      otherCertificationSchemes: 'Andere Zertifizierungsschemas',
      partners: 'Partner',
      processingAidGroups: 'Verarbeitungshilfsstoffgruppen',
      processingAids: 'Verarbeitungshilfsstoffe',
      processStepGroups: 'Prozessschrittgruppen',
      processSteps: 'Prozessschritte',
      productGroups: 'Produktgruppen',
      products: 'Produkte',
      reports: 'Berichte',
      requirements: 'Anforderungen',
      riskAssessments: 'Risikobewertungen',
      riskManagementTools: 'Risk Management tools',
      support: 'Unterstützung',
      undesirableSubstances: 'Unerwünschte Stoffe',
      units: 'Einheiten',
      managementGroupMembers: 'Gruppenmitglieder verwalten',
      acceptedFeedMaterials: 'Produktliste',
      cbLogin: 'CB/Auditor/Committee Portal',
      exemptions: 'Befreiungen',
      helpdesk: 'Support',
      helpdeskLink: 'https://www.gmpplus.org/de/support-platform/',
    },
  },
  form: {
    unit: {
      updateTitle: 'Einheit aktualisieren',
      createTitle: 'Einheit erstellen',
      updateDescription: 'Füllen Sie die erforderlichen Felder aus.',
      createDescription: 'Füllen Sie die erforderlichen Felder aus.',
    },
    processingAidGroup: {
      updateTitle: 'Verarbeitungshilfsstoffgruppe aktualisieren',
      createTitle: 'Verarbeitungshilfsstoffgruppe erstellen',
      updateDescription: 'Füllen Sie die erforderlichen Felder aus.',
      createDescription: 'Füllen Sie die erforderlichen Felder aus.',
    },
    processSteps: {
      updateTitle: 'Prozessschritte aktualisieren',
      createTitle: 'Prozessschritte erstellen',
      createDescription: 'Füllen Sie die erforderlichen Felder aus.',
    },
    products: {
      updateTitle: 'Produkte aktualisieren',
      createTitle: 'Produkte erstellen',
      createDescription: 'Füllen Sie die erforderlichen Felder aus.',
    },
    processingAids: {
      updateTitle: 'Verarbeitungshilfsstoffe aktualisieren',
      createTitle: 'Verarbeitungshilfsstoffe erstellen',
      createDescription: 'Füllen Sie die erforderlichen Felder aus.',
    },
    flowchart: {
      updateTitle: 'Flussdiagramm aktualisieren',
      createTitle: 'Flussdiagramm erstellen',
      createDescription: 'Fügen Sie Flussdiagramme zur Risikobewertung hinzu.',
    },
    riskAssessment: {
      updateTitle: 'Risikobewertung aktualisieren',
      createTitle: 'Risikobewertung erstellen',
      createDescription: 'Füllen Sie die erforderlichen Felder aus.',
    },
    processLinking: {
      updateTitle: 'Prozessverknüpfung aktualisieren',
      createTitle: 'Prozessverknüpfung erstellen',
    },
    hazards: {
      updateTitle: 'Gefahren aktualisieren',
      createTitle: 'Gefahren erstellen',
    },
  },
  dialog: {
    cancelChanges: {
      title: 'Sind Sie sicher, dass Sie abbrechen möchten?',
      description: 'Alle nicht gespeicherten Änderungen gehen verloren.',
    },
  },
  detail: {
    table: {
      originLocation: 'Ursprungsort',
      product: 'Produkt',
      dateReplied: 'Datum der Beantwortung',
      dateReceived: 'Datum des Erhalts',
      name: 'Name',
      code: 'Code',
      active: 'Aktiv',
      productGroup: 'Produktgruppe',
      activeRiskAssessmentMonitoring: 'Aktiv für Risikobewertung',
      euProduct: 'EU-Produkt',
      file: 'Datei',
      versionDate: 'Versionsdatum',
      sortSequence: 'Sortierreihenfolge',
      description: 'Beschreibung',
      version: 'Version',
      publicationDate: 'Veröffentlichungsdatum',
      changeDate: 'Änderungsdatum',
      status: 'Status',
      hazard: 'Gefahr',
      hazardName: 'Gefahrenname',
      referenceDiagram: 'Referenzdiagramm',
      likelyOccurrence: 'Wahrscheinliches Auftreten',
      explanation: 'Erläuterung',
      controlMeasures: 'Kontrollmaßnahmen',
      processStepName: 'Prozessschrittname',
      severity: 'Schweregrad',
      parentHazard: 'Übergeordnete Gefahr',
      category: 'Kategorie',
      location: 'Standort',
      order: 'Reihenfolge',
      detectionLimit: 'Nachweisgrenze',
      unit: 'Einheit',
      method: 'Methode',
      processingAidGroup: 'Verarbeitungshilfsstoffgruppe',
      factsheet: 'Fact sheet',
      feedMaterials: 'Produktliste',
      search: 'Suchen',
      selectUndesirableSubstances: 'Unerwünschte Stoffe auswählen',
      selectProcessingAids: 'Verarbeitungshilfsstoffe auswählen',
      selectFeedMaterials: 'Produkte auswählen',
      rowsPerPage: 'Zeilen pro Seite',
      rows: 'Zeilen',
      definition: 'Definition',
      synonyms: 'Synonyme',
      showAllSelectedItems: 'Alle ausgewählten Elemente anzeigen',
      sampleLevel: 'Stufe',
      sampleDate: 'Probendatum',
      sampleDateRange: 'Probenzeitraum',
      sampleNumber: 'Probenummer',
      typeOfAnalysis: 'Analysetyp',
      batchNumber: 'Chargennummer',
      origin: 'Herkunft',
      producer: 'Hersteller',
      supplier: 'Lieferant',
      characteristics: 'Eigenschaften',
      equalsResult: '=',
      greaterThanResult: '>',
      lessThanResult: '<',
      none: '-',
      dateOfReport: 'Berichtsdatum',
      note: 'Notiz',
      lowerThanResult: '<',
      plannedSampleNumber: 'Geplante Probennummer',
      plannedSampleDate: 'Geplantes Zeitraum',
      moist: 'Feucht',
      hazardType: 'Gefahrtyp',
      unitCode: 'Einheits code',
      unitName: 'Einheits name',
      action: 'Aktion',
      productName: 'Produktname',
      sort: 'Sortieren',
      group: 'Gruppe',
      remarks: 'Bemerkungen',
      anonymous: 'Anonym',
      GMPPlusCommunity: 'GMP+ Gemeinschaft',
      selected: 'Ausgewählt',
      registrationNumber: 'Registrierungsnummer',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      new: 'Neu',
      isActive: 'Ist aktiv',
      city: 'Stadt',
      country: 'Land',
      minimumActionBorder: 'Mindestaktionsgrenze',
      maximumActionBorder: 'Maximale Aktionsgrenze',
      minimumRejectionBorder: 'Mindestablehnungsgrenze',
      maximumRejectionBorder: 'Maximale Ablehnungsgrenze',
      isActiveMonitoring: 'Ist aktive Überwachung',
      sortOrder: 'Sortierreihenfolge',
      isActiveForRiskAssessment: 'Ist aktiv für Risikobewertung',
      moistureContentConversion: 'Feuchtigkeitsgehaltsumrechnung',
      analysisBaseName: 'Analysebasisname',
      laboratory: 'Labor',
      reportNumber: 'Berichtsnummer',
      fileName: 'Dateiname',
      fileSize: 'Dateigröße',
      receivedAt: 'Empfangen am',
      convertedAt: 'Konvertiert am',
      importedAt: 'Importiert am',
      done: 'Erledigt',
      succeeded: 'Erfolgreich',
      hasErrors: 'Hat Fehler',
      emailAddress: 'E-Mail-Adresse',
      attachments: 'Anhänge',
      isEuProduct: 'EU Produkt',
      convertedSample: 'Proben',
      startSampleDate: 'Sample startdatum',
      endSampleDate: 'Monster enddatum',
    },
    header: {
      generalInformation: 'Allgemeine Informationen',
      feedMaterials: 'Produktliste',
      processingAids: 'Verarbeitungshilfsstoffe',
      flowcharts: 'Flussdiagramme',
      processSteps: 'Prozessschritte',
      processLinking: 'Prozessverknüpfung',
      hazards: 'Gefahren',
      standardDocuments: 'Scheme Dokumente',
      factSheetDocument: 'Fact sheet-Dokument',
      factSheetDocuments: 'Fact sheet-Dokumente',
      undesirableSubstances: 'Unerwünschte Stoffe',
      sharedWithGroups: 'Mit Gruppen geteilt',
      copiedToLocations: 'An Standorte kopiert',
      name: 'Name',
      anonymous: 'Anonym',
      belowFillInAllRequiredFieldsForThisProcessStepHazard:
        'Füllen Sie unten alle erforderlichen Felder für diesen Prozessschritt und diese Gefahr aus',
      editHazard: 'Gefahr bearbeiten',
      belowFillInAllRequiredFieldsForThisRiskAssessment:
        'Füllen Sie unten alle erforderlichen Felder für diese Risikobewertung aus',
      belowSelectAllTheProcessingAidsThatApplyToThisRiskAssessment:
        'Wählen Sie unten alle Verarbeitungshilfsstoffe aus, die auf diese Risikobewertung zutreffen',
      availableProcessingAids: 'Verfügbare Verarbeitungshilfsstoffe',
      addMember: 'Mitglied hinzufügen',
      groupInvitations: 'Gruppeneinladungen',
      activeGroups: 'Aktive Gruppen',
      selectedLocation: 'Ausgewählter Standort',
      controlMeasureGroup: 'Kontrollmaßnahmen-Gruppe',
      hazardLimits: 'Grenzwerte',
      riskAssessments: 'Risikobewertungen',
      childHazards: 'Untergefahren',
      product: 'Produkt',
    },
    button: {
      readFullText: 'Vollständigen Text lesen',
      copySample: 'Probe kopieren',
      shareSample: 'Probe teilen',
      viewSample: 'Probe ansehen',
      viewReport: 'Report ansehen',
      edit: 'Bearbeiten',
      close: 'Schließen',
      save: 'Speichern',
      today: 'Heute',
      select: 'Auswählen',
      cancel: 'Abbrechen',
    },
    status: {
      analysed: 'Analysiert',
      copied: 'Kopiert',
      elapsed: 'Abgelaufen',
      final: 'Endgültig',
      planned: 'Geplant',
      taken: 'Genommen',
    },
    sample: {
      GMPCommunitySamples: 'GMP+ Gemeinschaftsproben',
      ownSamples: 'Eigene Proben',
      copiedSamples: 'Kopierte Proben',
      finalise: {
        success: 'Probe erfolgreich abgeschlossen',
        error: 'Beim Abschließen der Probe ist ein Fehler aufgetreten',
        pending: 'Probe abschließen...',
      },
      copy: {
        success: 'Probe erfolgreich kopiert',
        error: 'Beim Abschließen der Probe ist ein Fehler aufgetreten',
        pending: 'Probe abschließen...',
      },
      share: {
        success: 'Probe erfolgreich geteilt',
        error: 'Beim Versuch, die Probe zu teilen, ist ein Fehler aufgetreten',
        pending: 'Probe wird geteilt...',
      },
    },
    errors: {
      anIssueOccurredWhileUpdatingTheHazard:
        'Beim Aktualisieren der Gefahr ist ein Problem aufgetreten',
      anIssueOccurredWhileUpdatingTheRiskAssessment:
        'Beim Aktualisieren der Risikobewertung ist ein Problem aufgetreten',
    },
  },
  error: {
    title: {
      notFound: 'Nicht gefunden',
      serverError: 'Fehler',
      unauthorized: 'Entschuldigung',
      forbidden: 'Verboten',
      badRequest: 'Bad Request',
    },
    message: {
      unauthorized:
        'Entschuldigung, Sie haben nicht die erforderlichen Berechtigungen, um auf diesen Bereich des Portals zuzugreifen. Wenn Sie der Meinung sind, dass Sie diese Nachricht fälschlicherweise erhalten haben, wenden Sie sich bitte an unser Support-Team, um weitere Hilfe zu erhalten. Wir entschuldigen uns für etwaige Unannehmlichkeiten und sind hier, um Ihnen bei der schnellen Lösung dieses Problems zu helfen.',
      notFound: 'Die Ressource konnte nicht gefunden werden',
      forbidden: 'Sie haben keine Berechtigung, auf diese Ressource zuzugreifen',
      badRequest: 'Die Anfrage ist ungültig',
      serverError: 'Ein Fehler ist aufgetreten',
    },
    returnToHome: 'Zurück zur Startseite',
    noResultsFound: 'Keine Ergebnisse gefunden basierend auf Ihren Suchkriterien',
    youCanTryADifferentSearch:
      'Sie können eine andere Suche versuchen, auf Tippfehler prüfen oder Ihre Filter ändern.',
    file: {
      upload: 'Beim Hochladen der Datei ist ein Fehler aufgetreten',
      delete: 'Beim Löschen der Datei ist ein Fehler aufgetreten',
      required: 'Bitte wählen Sie eine Datei aus',
    },
  },
  codelists: {
    title: 'Code-Listen',
    issuerId: {
      introText:
        '<b>issuerID</b> Codeliste mit den Registriernummern von Einsendern. Nach GMP+ zugelassene Labore verwenden ihre GMP+-Registriernummer. Nicht-GMP+-Labore erhalten nach Anmeldung bei GMP+ International eine L-Nummer.',
    },
    countryOfOrigin: {
      introText:
        "<b>countryOfOrigin</b> Codeliste der Länder, die in der  GMP+ Monitoring database gelistet sind. Sie können auch 'ZZZ' für 'Mehrere Länder' oder 'XXX' für 'Unbekannter Herkunft' verwenden.",
    },
    method: {
      introText:
        '<b>method</b> Codeliste zu den Methoden, die in der GMP+ Monitoring database geführt werden.',
    },
    parameter: {
      introText:
        '<b>parameter</b> Codeliste zu (un-)erwünschten Substanzen, die in der GMP+ Monitoring database aufgenommen sind. Darin sind auch die Codes (und Namen) der Einheiten enthalten, die zu (un-)erwünschten Substanzen gehören. Diese benötigen Sie auch zur Erstellung der elektronischen Nachrichtendatei.',
    },
    product: {
      introText:
        '<b>product</b> Codeliste der Erzeugnisse, die in der GMP+ Monitoring database gelistet sind. ',
      group: {
        category: {
          feedMaterial: 'Futtermitteln',
          additives: 'Zusatzstoffe',
          premixtures: 'Vormischungen',
          compoundFeeds: 'Mischfuttermittel',
        },
      },
    },
    producerSupplier: {
      introText:
        '<b>producer/supplier</b> Codelisten aller nach GMP+ zertifizierten Hersteller / Lieferanten.',
    },
    introText:
      '<div>Um die digitale Nachricht zu erstellen, benötigen Sie unter anderem die Codes der (un)erwünschten Stoffe, die in der GMP+ Monitoring database enthalten sind. Auf dieser Seite können Sie die erforderlichen Code-Listen einsehen.</div>',
    headers: {
      code: 'Code',
      country: 'Land',
      city: 'Stadt',
      name: 'Name',
      producer: 'Hersteller',
      unitCode: 'Einheitencode',
      hazardType: 'Gefahrentyp',
      description: 'Beschreibung',
      labType: 'Labortyp',
    },
  },
  samples: {
    headers: {
      name: 'Name',
      level: 'Stufe',
      location: 'Standort',
      product: 'Produkt',
      sampleNumber: 'Probenummer',
      sampleDate: 'Zeitraum',
      typeOfAnalysis: 'Analysetyp',
      status: 'Status',
      characteristics: 'Eigenschaften',
      supplier: 'Lieferant',
      producer: 'Hersteller',
      origin: 'Herkunft',
      batchNumber: 'Chargennummer',
      reportNumber: 'Berichtnummer',
      dateOfReport: 'Berichtsdatum',
      undesirableSubstance: 'Unerwünschter Stoff',
      method: 'Methode',
      analysedResult: 'Analysiertes Ergebnis',
      convertedResult: 'Umgerechnetes Ergebnis',
      actionLimit: 'Aktionsgrenze',
      rejectionLimit: 'Ablehnungsgrenze',
      unit: 'Einheit',
      analysisBase: 'Analysebasis',
      symbol: 'Symbol',
      moistureTested: '% Feuchtigkeit getestet',
      areYouSureThisSampleIsReady: 'Sind Sie sicher, dass diese Probe bereit ist?',
      youAreAboutToFinaliseThisSampleAndAllItsDataYouWillNotBeAbleToEditOrDeleteThisSampleAndThisActionCannotBeUndone:
        'Sie sind dabei, diese Probe und alle zugehörigen Daten abzuschließen. Sie können diese Probe nicht mehr bearbeiten oder löschen und diese Aktion kann nicht rückgängig gemacht werden.',
      createPlannedSample: 'Geplante Probe erstellen',
      editPlannedSample: 'Geplante Probe bearbeiten',
      createSample: 'Probe erstellen',
      editSample: 'Probe bearbeiten',
      createTracingData: 'Rückverfolgungsdaten erstellen',
      editTracingData: 'Rückverfolgungsdaten bearbeiten',
      create: 'Erstellen',
      cancel: 'Abbrechen',
      save: 'Speichern',
      analysisType: 'Analysetyp',
      factsheet: 'Fact sheet',
      reference: 'Referenz',
    },
    nav: {
      generalInformation: 'Allgemeine Informationen',
      analysisReport: 'Analysebericht',
      tracingData: 'Rückverfolgungsdaten',
    },
    forms: {
      editTracingData: 'Rückverfolgungsdaten bearbeiten',
      addAnalysisReport: 'Analysebericht hinzufügen',
      editAnalysisReport: 'Analysebericht bearbeiten',
      batchNumber: 'Chargennummer',
      tracingData: 'Rückverfolgungsdaten',
      origin: 'Herkunft',
      producer: 'Hersteller',
      supplier: 'Lieferant',
      laboratory: 'Labor',
      analysisSets: 'Analyse-Sets',
      note: 'Notiz',
      reportNumber: 'Berichtnummer',
      analysisDeviates: 'Analyse weicht von Standard 12% Feuchtigkeit ab',
      undesirableSubstance: 'Unerwünschter Stoff',
      method: 'Methode',
      symbol: 'Symbol',
      result: 'Ergebnis',
      AddGeneralInformation: 'Allgemeine Informationen hinzufügen',
      addTracingData: 'Rückverfolgungsdaten hinzufügen',
      editGeneralInformation: 'Allgemeine Informationen bearbeiten',
      dateOfReport: 'Berichtsdatum',
      product: 'Produkt',
      sampleNumber: 'Probenummer',
      typeOfAnalysis: 'Analysetyp',
      generalInformation: 'Allgemeine Informationen',
      publicationDate: 'Veröffentlichungsdatum',
      enterNewSample: 'Neue Probe eingeben',
      planNewSample: 'Neue Probe planen',
      FillOutTheGeneralInformationBelow: 'Füllen Sie die allgemeinen Informationen unten aus',
      date: 'Datum',
      year: 'Jahr',
      month: 'Monat',
      plannedAnalysis: 'Geplante Analyse',
      FillOutThePlannedAnalysisBelow:
        'Wählen Sie einen vordefinierten Analysensatz aus, um schnell eine Reihe von unerwünschten Substanzen auszuwählen, oder wählen Sie manuell die unerwünschten Substanzen aus, die Sie analysieren möchten.',
      selectAnalysisSets: 'Analyse-Sets selecteren',
      showSelectedItems: 'Alle ausgewählten Elemente anzeigen',
      characteristics: 'Eigenschaften',
      analysisDeviatesTooltip:
        'Der Analysetyp gibt an, zu welchem Zweck die Probe entnommen wurde. {break} GMP+ Analyse: Analyse im Rahmen der Mindestverpflichtung unter GMP+ Bedingungen (TS1.7 Monitoring) {break} Andere Analyse: Analyse im Rahmen der eigenen Überwachung. Bitte beachten Sie; in Zukunft wird es möglich sein, eigene Analysetypen zu erfassen.',
      analysisOriginTooltip:
        "Wählen Sie das Herkunftsland aus. Sie können auch 'Mehrere Länder' oder 'Unbekanntes Herkunftsland' auswählen.",
    },
    share: {
      doYouWantToShareThisSample: 'Möchten Sie diese Probe teilen?',
      available: 'Verfügbar',
      alreadySharedWith: 'Bereits geteilt mit',
      notAnonymous: 'nicht anonym',
      anonymous: 'anonym',
    },
    errors: {
      analysisReportNotCreated: 'Der Analysebericht konnte nicht erstellt werden',
      belowFillInAllRequiredFieldsForAnSample:
        'Füllen Sie unten alle erforderlichen Felder für eine Probe aus',
      youHaveUnsavedChangesAreYouSureYouWantToCancel:
        'Sie haben nicht gespeicherte Änderungen. Sind Sie sicher, dass Sie abbrechen möchten?',
    },
    months: {
      january: 'Januar',
      february: 'Februar',
      march: 'März',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember',
    },
  },
  riskAssessment: {
    headers: {
      cover: 'Deckblatt',
      products: 'Produkte',
      processingAids: 'Verarbeitungshilfsstoffe',
      flowCharts: 'Flussdiagramme',
      processSteps: 'Prozessschritte',
      processLinking: 'Prozessverknüpfung',
      hazards: 'Gefahren',
      name: 'Name',
      code: 'Code',
      productGroup: 'Produktgruppe',
      activeRiskAssessment: 'Aktive Risikobewertung',
      activeMonitoring: 'Aktive Überwachung',
      euProduct: 'EU-Produkt',
      processingAidsGroup: 'Verarbeitungshilfsstoffgruppe',
      riskAssessment: 'Risikobewertung',
      factsheet: 'Fact sheet',
      description: 'Beschreibung',
      group: 'Gruppe',
      sortSequence: 'Sortierreihenfolge',
      processStepName: 'Prozessschrittname',
      productName: 'Produkte',
      previewLabel: 'Vorschau - Diese Risikobewertung wurde noch nicht veröffentlicht.',
      riskAssessments: 'Risikobewertungen',
      version: 'Version',
      feedMaterials: 'Produktliste',
      synonyms: 'Synonyme',
      definition: 'Definition',
      modifiedDate: 'Änderungsdatum',
      filterFeedMaterials: 'Filter Produkte',
      publicationDate: 'Veröffentlichungsdatum',
      changeDate: 'Änderungsdatum',
      filterProcessingAids: 'Filter Verarbeitungshilfsstoffe',
      createHazardReport: 'Gefahrenbericht erstellen',
      status: 'Status',
    },
    actions: {
      version: {
        pending: 'Neue Version erstellen',
        success: 'Neue Version erstellt',
        error: 'Beim Erstellen einer neuen Version ist ein Fehler aufgetreten',
      },
      publish: {
        pending: 'Risikobewertung veröffentlichen',
        success: 'Risikobewertung veröffentlicht',
        error: 'Beim Veröffentlichen der Risikobewertung ist ein Fehler aufgetreten',
      },
      delete: {
        pending: 'Risikobewertung löschen',
        success: 'Risikobewertung gelöscht',
        error: 'Beim Löschen der Risikobewertung ist ein Fehler aufgetreten',
      },
      revoke: {
        pending: 'Risikobewertung zurückziehen',
        success: 'Risikobewertung zurückgezogen',
        error: 'Beim Zurückziehen der Risikobewertung ist ein Fehler aufgetreten',
      },
    },
    hazardReport: {
      availableFeedMaterials: 'Verfügbare Produkte',
      title: '(Un)erwünschte Stoffe Bericht',
      products: 'Produkte',
      hazard: '(Un)erwünschter Stoff',
      cover: 'Deckblatt',
      table: 'Bericht',
      description:
        'Wenn Ihr Produkt aus mehreren Produkte besteht (zum Beispiel ein Mischfutter), ist es möglich, einen kombinierten Gefahrenbericht zu erstellen. Der Bericht enthält eine Liste der Gefahren und in welchem Futtermaterial sie vorhanden sind. Suchen und wählen Sie das (die) betreffende(n) Futtermaterial(ien) in der untenstehenden Tabelle aus und erstellen Sie einen Bericht (PDF), indem Sie auf die Schaltfläche "Bericht herunterladen" in der rechten oberen Ecke des Bildschirms klicken.',
    },
    document: {
      processingAids:
        'Verarbeitungshilfsstoffe, die während der Behandlung und Verarbeitung verwendet werden*',
      processingAidsDescription: `Von den bei der Produktion verwendeten Verarbeitungshilfsstoffen muss anhand einer Gefahrenanalyse nachgewiesen werden, dass die unbeabsichtigte, aber technisch unvermeidbare Anwesenheit von Rückständen dieser Verarbeitungshilfsstoffe oder ihrer Derivate im Endprodukt keine nachteiligen Auswirkungen auf die Tiergesundheit, die menschliche Gesundheit oder die Umwelt hat und keinen technologischen Effekt auf das Endprodukt hat.
      Bei der Durchführung der Gefahrenanalyse muss auch auf die Gefahren einer Überdosierung von Verarbeitungshilfsstoffen und das Vorhandensein von Verunreinigungen in den verwendeten Verarbeitungshilfsstoffen geachtet werden. Bei Verwendung als Futtermittelzusatzstoff gilt das oben Gesagte nicht und es muss die geltende Gemeinschaftsgesetzgebung und die damit vereinbare nationale Gesetzgebung eingehalten werden.`,
      hazardOne:
        '„Wahrscheinliches Auftreten“ ist die Wahrscheinlichkeit, dass eine Gefahr im Endprodukt vorhanden ist. Ihr HACCP-Team kann das wahrscheinliche Auftreten anhand von Messungen, Beobachtungen oder Erwartungen Ihrer unternehmensspezifischen Situation bestimmen.',
      hazardTwo:
        'Wenn {link} keine Grenzwerte für eine Gefahr enthält, müssen Sie einen Grenzwert festlegen, begründen und dokumentieren. Dieser Grenzwert basiert auf Forschungsergebnissen, z.B. Literaturstudien, Informationen aus der Branche, GMP+-Fact sheet zu unerwünschten Stoffen usw.',
      allRightsReserved:
        'Alle Rechte vorbehalten. Die Informationen in dieser Veröffentlichung dürfen auf dem Bildschirm betrachtet, heruntergeladen und gedruckt werden, solange dies für den eigenen, nicht kommerziellen Gebrauch erfolgt. Für jede andere gewünschte Verwendung ist vorher eine schriftliche Genehmigung von GMP+ International B.V. einzuholen.',
      disclaimer: 'HAFTUNGSAUSSCHLUSS:',
      disclaimerText:
        'Bei der vorliegenden Risikobewertung handelt es sich um eine generische Risikobewertung für Produkte, die gemäß den Anforderungen des GMP+ FC scheme genehmigt worden sind. Die Risikobewertung stützt sich auf der Risikobewertung der Feed Safety Database, wobei die Informationen aus jener Datenbank maßgeblich sind. Wir weisen darauf hin, dass es sich bei dem oben erwähnten Versionsdatum um das Veröffentlichungsdatum handelt und dass der Inhalt der Risikobewertung den Sachstand an jenem Datum wiedergibt. Diese Risikobewertung ist als ein „lebendes Dokument“ zu betrachten, Verbesserungsvorschläge sind denn auch immer sehr willkommen.',
      disclaimerTextTwo:
        'Auch wenn bei der Vorbereitung dieser Risikobewertung alle erdenklichen Vorsorgemaßnahmen ergriffen worden sind und alles unternommen worden ist, um die Richtigkeit der in dieser Risikobewertung enthaltenen Informationen zu gewährleisten, erfolgt diese Gewährleistung unter dem Vorbehalt von Änderungen und Abweichungen. GMP+ International übernimmt keinerlei Haftung für etwaige Fehler oder fehlende Informationen und etwaige Verluste oder Unannehmlichkeiten infolge solcher Änderungen oder Abweichungen.',
      enFallback:
        'Bitte beachten Sie: Das Original-Flussdiagramm ist in der gewünschten Sprache nicht verfügbar. Eine englische Version wird Ihnen unten für Ihre Bequemlichkeit zur Verfügung gestellt. Wir entschuldigen uns für etwaige Unannehmlichkeiten.',
      noFallback:
        'Leider ist das von Ihnen gesuchte Flussdiagramm derzeit nicht in Ihrer Sprache verfügbar. Wir arbeiten daran, diesen Inhalt so schnell wie möglich bereitzustellen. Vielen Dank für Ihre Geduld und Ihr Verständnis.',
    },
    introText:
      'Welche Risiken sind mit der Produktion von Futtermitteln verbunden?  In Zusammenarbeit mit GMP+-zertifizierten Unternehmen wurde eine Reihe von Risikobewertungen entwickelt. Und es gibt eines für jedes akzeptierte Einzelfuttermittel auf der Produktliste von GMP+ International.',
  },
  processingAids: {
    introText: `<div>Dieser Abschnitt der Datenbank enthält Fact sheets zu Verarbeitungshilfsstoffen. Verarbeitungshilfsstoffe werden bei der Produktion von Produkte verwendet. Der Einsatz von Verarbeitungshilfsstoffen ist im Rahmen von GMP+ erlaubt, vorausgesetzt, der Benutzer weist durch eine Gefahrenanalyse nach, dass die unbeabsichtigte, aber technisch unvermeidbare Anwesenheit von Rückständen oder deren Derivaten keine Auswirkungen auf die Tiergesundheit, die menschliche Gesundheit oder die Umwelt hat und keinen technologischen Effekt auf das Endprodukt hat.</div>
      <div>Die Fact sheets enthalten Details zur Durchführung der Gefahrenanalyse. Dies enthält einen Abschnitt über mögliche unerwünschte Stoffe, die in Futtermitteln vorhanden sind, und deren Auswirkungen auf Menschen, Tiere und die Umwelt; Kontrollmaßnahmen, die ergriffen werden können; und die Risiken, die sich aus hohen oder niedrigen Dosen von Verarbeitungshilfsstoffen ergeben können.</div>
      <div>Eine Übersicht zeigt alle Verarbeitungshilfsstoffe, die bei der Produktion von Produkte verwendet werden. Die Verarbeitungshilfsstoffe sind in mehrere Kategorien unterteilt. Ein Fact sheet ist für Verarbeitungshilfsstoffe verfügbar, die mit einem Download-Symbol gekennzeichnet sind.</div>`,
    aboutProcessingAids: 'Über Verarbeitungshilfsstoffe',
    factsheetIncluded: 'Fact sheet enthalten',
    included: 'Enthalten',
    notIncluded: 'Nicht enthalten',
    showRiskAssessments: 'Risikobewertungen anzeigen',
    headers: {
      name: 'Name',
      groups: 'Gruppen',
    },
  },
  products: {
    headers: {
      name: 'Name',
      code: 'Code',
      synonyms: 'Synonyme',
      definition: 'Definition',
      productGroup: 'Gruppe',
    },
    category: {
      none: 'Keine',
      feedMaterial: 'Futtermitteln',
      additives: 'Zusatzstoffe',
      premixtures: 'Vormischungen',
      compoundFeeds: 'Mischfuttermittel',
    },
  },
  hazards: {
    headers: {
      name: 'Name',
      code: 'Code',
      category: 'Kategorie',
      severity: 'Schweregrad',
      sortSequence: 'Sortierreihenfolge',
      limits: 'Grenzwerte',
      factsheet: 'Fact sheet',
      hazardGroup: 'Gefahrengruppe',
      definitions: 'Definitionen',
      synonyms: 'Synonyme',
      riskAssessments: 'Risikobewertungen',
      monitoringDatabaseResults: 'Ergebnisse der Monitoring database',
      factsheetIncluded: 'Factsheet enthalten',
      isActiveForRiskAssessment: 'Aktiv für Risikobewertung',
      isActiveMonitoring: 'Aktiv für Überwachung',
    },
    analysisBase: {
      none: 'Keine',
      moist12Percent: '12% Feucht',
      dryMatter: 'Trockenmasse',
      fatBasis: 'Fettbasis',
      product: 'Produkt',
      protein: 'Protein',
    },
    high: 'Hoch',
    medium: 'Mittel',
    low: 'Niedrig',
    limitsText:
      'Für diese Gefahr gelten Grenzwerte. Sie können diese Grenzwerte im Fact sheet einsehen, das über die Schaltfläche in der rechten oberen Ecke heruntergeladen werden kann. Möchten Sie mehr über alle spezifischen Futtersicherheitsgrenzen lesen? Laden Sie das vollständige Dokument herunter.',
    noLimitsNoFactSheet:
      'Dieser unerwünschte Stoff hat keine festgelegten GMP+-Grenzwerte. Sie sollten einen Grenzwert festlegen, begründen und dokumentieren. Dieser Grenzwert basiert auf Forschungsergebnissen, z. B. Literaturstudien, Informationen aus der Branche usw.',
    noLimits:
      'Diese unerwünschte Substanz hat keine festgelegten GMP+ Grenzwerte. Sie sollten einen Grenzwert festlegen, begründen und dokumentieren. Dieser Grenzwert basiert auf Forschung, z.B. Literaturstudien, Informationen aus dem Sektor, GMP+ Merkblatt zu unerwünschten Stoffen (herunterladbar über die Schaltfläche oben rechts), etc. \nDas Merkblatt beschreibt die Eigenschaften und Auswirkungen unerwünschter Stoffe. Zusätzlich enthält es eine Erklärung zur Schwere einer Gefahr, Details zur Durchführung der Analyse und welche Kontrollmaßnahmen ergriffen werden können, um zu verhindern, dass die unerwünschte Substanz in das Tierfutter gelangt.',
    factSheetAndLimits:
      'Das Merkblatt (herunterladbar über die Schaltfläche oben rechts) beschreibt die Eigenschaften und Auswirkungen unerwünschter Stoffe. Zusätzlich enthält es eine Erklärung zur Schwere einer Gefahr, Details zur Durchführung der Analyse und welche Kontrollmaßnahmen ergriffen werden können, um zu verhindern, dass die unerwünschte Substanz in das Tierfutter gelangt.',
    aboutUndesirableSubstances: 'Über unerwünschte Stoffe',
    downloadFactsheet: 'Fact sheet herunterladen',
    downloadStandardDocument: 'Spezifische Grenzwerte',
    introText: `<div>Die Qualitätspolitik von GMP+ International konzentriert sich auf die Reduzierung der Anwesenheit unerwünschter Stoffe in Futtermitteln. Jedoch können viele Stoffe, natürlich oder nicht, in bestimmten Produkten vorhanden sein. Die Risikobewertungen weisen diese unerwünschten Stoffe als Gefahr aus.</div>
    <div>Dieser Abschnitt enthält eine Vielzahl von Fact sheets, die die Eigenschaften und Auswirkungen unerwünschter Stoffe beschreiben. Darüber hinaus enthält es Details zur Schwere der Gefahr, wie die Analyse durchgeführt werden sollte und welche Kontrollmaßnahmen ergriffen werden können, um zu verhindern, dass der unerwünschte Stoff in das Futtermittel gelangt. In der Anleitung wird für jedes Kapitel erklärt, welche Informationen in den Fact sheets konsultiert werden können.</div>
    <h2 class="text-primary text-xl font-bold">Spezifische Studien</h2>
    <ul class="text-primary underline flex flex-col gap-2">
      <li><a href="/media/S 9.71 Study into drying processes for animal feed materials and HACCP.pdf" target="_blank" rel="noopener noreferrer">S 9.71 Studie zu Trocknungsprozessen für Produkte und HACCP</a></li>
      <li><a href="/media/S 9.72 Salts in rations with wet feed for fattening pigs and sows.pdf" target="_blank" rel="noopener noreferrer">S 9.72 Salze in Rationen mit Nassfutter für Mastschweine und Sauen</a></li>
      <li><a href="/media/S 9.73 Elaboration of limit values for PAH's in oil for feed.pdf" target="_blank" rel="noopener noreferrer">S 9.73 Ausarbeitung von Grenzwerten für PAK's in Öl für Futtermittel</a></li>
      <li><a href="/media/S 9.74 Transfer of Polycyclic Aromatic Hydrocarbons in oil for feed.pdf" target="_blank" rel="noopener noreferrer">S 9.74 Übertragung von Polyzyklischen Aromatischen Kohlenwasserstoffen in Öl für Futtermittel</a></li>
      <li><a href="/media/S 9.75 Norm for fungal load in feed.pdf" target="_blank" rel="noopener noreferrer">S 9.75 Norm für Pilzbelastung in Futtermitteln</a></li>
    </ul>`,
  },
  buttons: {
    copySample: 'Probe kopieren',
    shareSample: 'Probe teilen',
    downloadCsv: 'CSV herunterladen',
    exportCsv: 'CSV exportieren',
    downloadPdf: 'PDF herunterladen',
    edit: 'Bearbeiten',
    deleteSample: 'Probe löschen',
    addAndFillSection: 'Abschnitt hinzufügen und ausfüllen',
    tasks: 'Aufgaben',
    help: 'Hilfe',
    finalize: 'Abschließen',
    save: 'Speichern',
    add: 'Hinzufügen',
    addNewLine: 'Neue Zeile hinzufügen',
    noDoNotShare: 'Nein, nicht teilen',
    yesShare: 'Ja, teilen',
    close: 'Schließen',
    noCancel: 'Nein, abbrechen',
    yesRevoke: 'Ja, widerrufen',
    yesFinalize: 'Ja, abschließen',
    revoke: 'Widerrufen',
    yesPublish: 'Ja, veröffentlichen',
    publish: 'Veröffentlichen',
    cancel: 'Abbrechen',
    noContinue: 'Nein, fortsetzen',
    yesCancel: 'Ja, abbrechen',
    moreInfo: 'Mehr Info',
    readManual: 'Handbuch lesen',
    yesDelete: 'Ja, löschen',
    requestMissingCode: 'Fehlenden Code anfordern',
    createNewVersion: 'Neue Version erstellen',
    preview: 'Vorschau',
    revokePublishedVersion: 'Veröffentlichte Version widerrufen',
    next: 'Weiter',
    prev: 'Zurück',
    delete: 'Löschen',
    update: 'Aktualisieren',
    copy: 'Copy',
    share: 'Share',
    applyToAll: 'Auf alle anwenden',
    addNewMember: 'Neues Mitglied hinzufügen',
    accept: 'Akzeptieren',
    reject: 'Ablehnen',
  },
  modals: {
    areYouSureYouWantToRevokeThePublishedVersion:
      'Sind Sie sicher, dass Sie die veröffentlichte Version widerrufen möchten?',
    youAreAboutToRevokeTheFollowingRiskAssessment:
      'Sie sind dabei, die folgende Risikobewertung zu widerrufen',
    doingSoWill: 'Dies wird',
    setTheStatusToRevokedInTheManagementPortal:
      'Den Status auf "Widerrufen" im Managementportal setzen',
    removeThePublishedVersionOfTheClientPortalAndThereforeMakeItNotAccessibleForClientsAnymore:
      'Die veröffentlichte Version aus dem Kundenportal entfernen und daher nicht mehr für Kunden zugänglich machen',
    featureComingSoon: 'Funktion bald verfügbar',
    featureComingSoonDescription:
      'Wir arbeiten hart daran, Ihnen neue Funktionen zu bieten. Diese Funktionalität wird in Kürze verfügbar sein. Vielen Dank für Ihre Geduld und Ihr Verständnis.',
    deleteRiskAssessmentsTitle: 'Sind Sie sicher, dass Sie diese Risikobewertung löschen möchten?',
    deleteRiskAssessmentsDescription:
      'Sind Sie sicher, dass Sie diese Risikobewertung löschen möchten? Wenn bereits eine veröffentlichte Version der Risikobewertung existiert, wird diese nicht gelöscht und in diesem Fall wird nur die letzte Arbeitsversion gelöscht.',
    publishRiskAssessmentsTitle:
      'Sind Sie sicher, dass Sie diese Risikobewertung veröffentlichen möchten?',
    youAreAboutToPublishTheFollowingRiskAssessment:
      'Sie sind dabei, die folgende Risikobewertung zu veröffentlichen:',
    andAllItsDataThisAssessmentCanNoLongerBeChangedWhileBeingPublished:
      'und alle zugehörigen Daten. Diese Bewertung kann während der Veröffentlichung nicht mehr geändert werden.',
    afterPublishingItIsOptionalToWithdrawThePublishedVersionOrCreateANewVersionBasedOnThePublishedVersionToImplementAtALaterTime:
      'Nach der Veröffentlichung ist es optional, die veröffentlichte Version zurückzuziehen oder eine neue Version basierend auf der veröffentlichten Version zu erstellen, um diese zu einem späteren Zeitpunkt zu implementieren.',
  },
  tasks: {
    tasks: 'Aufgaben',
    finishGeneralInfo: 'Allgemeine Informationen abschließen',
    continueByFillingOutTheRemainingSampleInformation:
      'Fahren Sie fort, indem Sie die restlichen Probeninformationen ausfüllen',
    tracingData: 'Rückverfolgungsdaten',
    startHereByProvidingTracingDataForYourNewSample:
      'Beginnen Sie hier, indem Sie Rückverfolgungsdaten für Ihre neue Probe bereitstellen',
    analysisReport: 'Analysebericht',
    youHaveCompletelyFilledThisSample: 'Sie haben diese Probe vollständig ausgefüllt',
    allSectionsHaveBeenFilledOutAndAreMarkedAsDone:
      'Alle Abschnitte sind ausgefüllt und als erledigt markiert',
    enterAnalysisDataInToAnAnalysisReport: 'Geben Sie Analysedaten in einen Analysebericht ein',
    thisSampleIsNowComplete: 'Diese Probe ist nun abgeschlossen',
    createSample: 'Probe erstellen',
    yesConvert: 'Ja, umwandeln',
    areYouSureYouWantToCreateASampleOfThisPlannedSample:
      'Sind Sie sicher, dass Sie diese geplante Probe in ein Muster umwandeln möchten?',
    turnThisPlannedSampleIntoASample: 'Wandeln Sie diese geplante Probe in ein Muster um',
    undesirableSubstances: 'Unerwünschte Stoffe',
    addAndFillSection: 'Abschnitt hinzufügen und ausfüllen',
    doYouWantToShareThisSample: 'Möchtest du dieses Muster teilen?',
    shareSample: 'Muster teilen',
    yesShare: 'Ja, teilen',
    noDoNotShare: 'Nein, nicht teilen',
  },

  errors: {
    invalid_type: 'Dieses Ergebnis hat nicht das richtige Eingabeformat',
    required: 'Dieses Feld ist erforderlich',
    csv: 'Es ist ein Fehler beim Importieren der CSV-Datei aufgetreten',
  },
  feedSchemeDocuments: {
    title: 'GMP+ Scheme Dokumente',
    introText:
      '<div>Hier finden Sie die GMP+ Scheme Dokumente. Sie können das Dokument online einsehen oder die PDF-Version herunterladen. Die Dokumente sind in englischer, niederländischer und deutscher Sprache verfügbar. Sie können das Übersetzungstool Ihres Browsers verwenden, um die Dokumente in andere Sprachen zu übersetzen.</div>',
    version: 'Version',
    versionCompare: 'vergleich',
    versionCompareBanner:
      'Sie betrachten einen {versionCompare} zwischen der aktuellen Version ({currentVersion}) und der vorherigen Version ({previousVersion}).',
    viewOnline: 'Online ansehen',
    download: 'Herunterladen',
    schemeDocument: 'Scheme Dokument',
    compare: 'Vergleichen von Versionen',
    downloadVersionDiffPdf: 'Versionenunterschied als PDF herunterladen',
    closeVersionDiff: 'Versionenunterschied schließen',
    document: {
      availableLanguages: 'Dieses Dokument ist in {count} verschiedenen Sprachen verfügbar.',
      version: 'Version',
    },
  },
  documents: {
    title: 'Dokumente',
    introText:
      '<div>Hier finden Sie die GMP+ Scheme Dokumente. Sie können das Dokument online einsehen oder die PDF-Version herunterladen. Die Dokumente sind in englischer, niederländischer und deutscher Sprache verfügbar. Sie können das Übersetzungstool Ihres Browsers verwenden, um die Dokumente in andere Sprachen zu übersetzen.</div>',
    header: {
      title: 'Titel',
      number: 'Nummer',
      typeShort: 'Typenkürzel',
      languages: 'Verfügbare Sprachen',
      version: 'Version',
    },
  },
  feedMaterials: {
    nav: {
      productInformation: 'Produktinformationen',
      analysisReport: 'Analysebericht',
      tracingData: 'Rückverfolgungsdaten',
      riskAssessments: 'Risikobewertungen',
    },
    definitions: 'Definitionen',
    definition: 'Definition',
    name: 'Name',
    code: 'Code',
    synonyms: 'Synonyme',
    riskAssessments: 'Risikobewertungen',
    monitoringDatabaseResults: 'Ergebnisse der Monitoring database',
    feedMaterialsThisFeedMaterialIsNotAuthorisedInTheEUCheckLegalStatusInOtherCountries:
      'Dieses Produkte ist in der EU nicht zugelassen. Überprüfen Sie den rechtlichen Status in anderen Ländern.',
    showTheAssessments: 'Die Bewertungen anzeigen',
    publicationDate: 'Veröffentlichungsdatum',
  },
  greeting: {
    morning: 'Guten Morgen',
    afternoon: 'Guten Nachmittag',
    evening: 'Guten Abend',
  },
  analysisSets: {
    headers: {
      name: 'Name',
      location: 'Standort',
      status: 'Status',
      detectionLimit: 'Nachweisgrenze',
      method: 'Methode',
      unit: 'Einheit',
    },
    errors: {
      belowFillInAllRequiredFieldsForAnalysisSets:
        'Wählen Sie unten alle unerwünschten Stoffe aus, die in dieses Analyse-Set aufgenommen werden sollen',
    },
    forms: {
      generalInformation: 'Allgemeine Informationen',
      availableUndesirableSubstances: 'Verfügbare unerwünschte Stoffe',
      showSelectedItem: 'Ausgewählte Elemente anzeigen',
      selectUndesirableSubstance: 'Unerwünschten Stoff auswählen',
      selectMethod: 'Methode auswählen',
    },
    createAnalysisSet: 'Analyse-Set erstellen',
    next: 'Weiter',
    prev: 'Zurück',
    generalInformation: 'Allgemeine Informationen',
    editAnalysisSet: 'Analyse-Set bearbeiten',
  },
  dashboard: {
    hub: {
      welcome: 'Willkommen im Management-Dashboard',
      welcomeDescription:
        'Willkommen in Ihrem Management-Dashboard! Hier haben Sie die Tools und Ressourcen zur Hand, um Stammdaten effektiv zu verwalten, umfassende Risikobewertungen durchzuführen und andere kritische Aspekte Ihrer Abläufe zu überwachen. Dieses Portal ist so konzipiert, dass es Ihnen optimierte Workflows und Echtzeitdaten bietet, damit Sie fundierte Entscheidungen treffen und eine optimale betriebliche Effizienz aufrechterhalten können. Erkunden Sie die verfügbaren Funktionen und verbessern Sie noch heute Ihre Management-Fähigkeiten.',
    },
    portal: {
      welcome: 'Willkommen im GMP+ Portal',
      welcomeDescription:
        'Vielen Dank für Ihren Besuch im neuen GMP+ Portal. Diese Plattform wurde entwickelt, um Ihnen den Zugang zu unseren umfassenden Ressourcen, Tools und Updates im Bereich Futtersicherheitsmanagement zu erleichtern. Unser Ziel ist es, Sie dabei zu unterstützen, die höchsten Standards in Bezug auf Qualität und Sicherheit einzuhalten. Erkunden Sie das Portal, um maßgeschneiderte Lösungen und fachkundige Beratung zu finden, die Ihnen helfen können, Ihre Abläufe zu verbessern. Wir sind hier, um Sie bei jedem Schritt zu unterstützen.',
      provideYourFeedback: 'Geben Sie Ihr Feedback',
      doYouHaveAnyFeedbackForUs:
        'Haben Sie Feedback für uns? Durch Klicken auf diesen Button können Sie uns über etwaige Probleme informieren.',
      feedback: 'Feedback',
      demo: 'Demo',
      startADemoTourOfTheNewOnlineEnvironment:
        'Starten Sie eine Demotour durch die neue Online-Umgebung.',
      startDemo: 'Demo starten',
      learningAndDevelopment: 'Learning & Development',
      academy: 'GMP+ Academy',
      joinAcademy:
        'Schließen Sie sich der GMP+ Academy an und verbessern Sie die weltweite Futtersicherheit und Nachhaltigkeit! Zugang zu Expertenschulungen, internationalen Trainern und Online-Kursen!',
      academyLink: 'https://www.gmpplusacademy.org/',
    },
    certifiedCompanies: 'GMP+ Company database',
    feedSchemeDocuments: 'GMP+ Scheme Dokumente',
    riskAssessments: 'Risikobewertungen',
    aboutDescription:
      'GMP+ International teilt wertvolles Wissen über Futtersicherheit mit zertifizierten Unternehmen durch unsere Feed Support Products (FSP). Dies ist eine Informationsquelle, die allen in der Futtermittelkette zugutekommt.',
    about: 'Über',
    manageRiskAssessments: 'Risikobewertungen verwalten',
    manageHazards: 'Gefahren verwalten',
    manageProducts: 'Produkte verwalten',
  },
  allowedFeedMaterials: {
    introText:
      'In diesem Abschnitt der Risikomanagement-Tools finden Sie die Produktliste. Dies ist die Liste der akzeptierte Einzelfuttermittel, die innerhalb der GMP+-zertifizierten Kette hergestellt und gehandelt werden dürfen.',
    createHazardReport: 'Erstellen Sie einen Gefahrenbericht',
  },
  monitoringGroup: {
    introText:
      'In der GMP+ Monitoring database wurden Vorkehrungen für die Zusammenarbeit verschiedener Unternehmen getroffen. Diese Gruppen können aus Mitgliedern einer Vereinigung, verschiedenen Standorten eines Unternehmens oder einem Lieferanten mit seinen Kunden bestehen. Unten sind die Gruppen aufgeführt, deren Mitglied Sie sind.',
    headers: {
      group: 'Gruppe',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      dateAgreedByLocation: 'Vereinbarungsdatum',
      status: 'Status',
      authorized: 'Gruppe für Eingaben autorisiert',
    },
    applyForGroupManager: {
      title: 'Bewerben Sie sich für die Rolle des „Gruppenmanagers“',
      text: 'Wenn Sie planen, eine Gruppe selbst zu verwalten, aber noch kein Gruppenmanager-Konto haben, können Sie sich mit',
      button: 'diesem Formular',
    },
  },
  managementGroupMembers: {
    introText:
      'Unten finden Sie eine Übersicht der Mitglieder (besser bekannt als Standorte) der Gruppe, deren Manager Sie sind. Hier können Sie die Mitgliedschaft der Gruppenmitglieder bearbeiten. Als Gruppenmanager können Sie neue Mitglieder zur Gruppe einladen und die Mitgliedschaft aktueller Mitglieder widerrufen.',
    note: 'Bitte beachten Sie: Wenn der Gruppenmanager berechtigt ist, Ergebnisse im Namen eines Standorts einzugeben, kann er/sie dies in der Überwachungsübersicht ’Meine Überwachung’ tun.',
    locationsInGroup: 'Standorte in der Gruppe',
    noAdminGroups: 'Es sieht so aus, als ob du noch kein Gruppenmanager einer Gruppe bist.',
    headers: {
      registratioNr: 'Registrierungsnummer',
      location: 'Standort',
      startDate: 'Startdatum',
      endDate: 'Enddatum',
      agreedLocation: 'Vereinbarter Standort',
      status: 'Status',
      groupAuthorizedForInput: 'Gruppe berechtigt zur Eingabe',
      group: 'Gruppe',
      monitoringGroup: 'Monitoring Gruppe',
      addMember: 'Mitglied hinzufügen',
      search: 'Suchen',
    },
    step: [
      {
        text: "You're logged in as Group Manager, below select all the locations you want to add as new members to the group {groupname}. the start and end dates can be defined in the next step.",
      },
      {
        text: 'Please define a start and end date for the membership or set them individually.',
      },
    ],
  },
  select: {
    salmonella: {
      title: 'Bitte wählen Sie den Salmonellen-Typ aus',
      intro: 'Bitte geben Sie an, welcher Salmonellen-Typ bei der Analyse gefunden wurde:',
      search: 'Salmonellen-Typ suchen',
    },
    location: {
      title: 'Probe an Standort kopieren',
      intro: 'Bitte wählen Sie einen Standort aus der untenstehenden Liste aus:',
      search: 'Standort suchen',
      share: 'Kopieren',
      shareCommunity: 'Mit GMP+ Community teilen',
    },
    sampleShare: {
      title: 'Probe mit Überwachungsgruppe teilen',
      intro: 'Bitte wählen Sie eine Überwachungsgruppe aus der Liste unten:',
      search: 'Überwachungsgruppe suchen',
      share: 'Teilen',
      shareCommunity: 'Mit der GMP+ Gemeinschaft teilen',
    },
    hazardType: {
      Value: 'Werd',
      Toggle: 'Vorhand/Nicht vorhand',
    },
  },
  externalLinks: {
    earlyWarningSystem: 'https://portal.gmpplus.org/de-DE/ews-support/ews-cases/',
    companyDatabase: {
      certifiedCompanies: 'https://portal.gmpplus.org/de-DE/cdb/certified-companies/',
      certificationBodies: 'https://portal.gmpplus.org/de-DE/cdb/certification-body/',
      laboratories: 'https://www.gmpplus.org/de/zertifizierungssystem/company-database/lab/',
      partners: 'https://portal.gmpplus.org/de-DE/cdb/partners/',
      otherCertificationSchemes:
        'https://portal.gmpplus.org/de-DE/cdb/other-certification-schemes/',
    },
    missingCodes: 'https://fd8.formdesk.com/gmpinternational/aanvraag_codes_monitoring_deu',
    applyForGroupManager:
      'https://fd8.formdesk.com/gmpinternational/Aanvragen_account_voor_groepsbeheerder_de',
    TS1: 'https://www.gmpplus.org/de/zertifizierungssystem/scheme-dokumente/fsa-anforderungen/ts15?id=d0c22ef7-9577-f05f-dafc-8904d9d7ea9e',
    cbLogin: 'https://portal.gmpplus.org/de-DE/',
    exemptions: 'https://portal.gmpplus.org/de-DE/my-exemptions/',
  },
  toasts: {
    create: {
      pending: 'Erstelle {name}...',
      error: 'Erstellung von {name} fehlgeschlagen',
      success: '{name} erfolgreich erstellt',
    },
    update: {
      pending: 'Aktualisiere {name}...',
      error: 'Aktualisierung von {name} fehlgeschlagen',
      success: '{name} erfolgreich aktualisiert',
    },
    report: {
      pending: 'Bericht wird geladen...',
      error: 'Beim Laden des Berichts ist ein Fehler aufgetreten',
      success: 'Bericht erfolgreich geladen',
    },
    csv: {
      pending: 'CSV-Export erstellen...',
      error: 'Fehler beim Exportieren der CSV-Datei.',
      success: 'CSV erfolgreich exportieren',
    },
    copyLink: {
      success: 'Link zum Proben in die Zwischenablage kopiert',
      error: 'Der Link zum Proben konnte nicht kopiert werden',
    },
  },
  filters: {
    factsheetIncluded: 'Fact sheet enthalten',
  },
  noItemsFound: 'Keine Items mit dem Suchbegriff gefunden',
  selectItem: 'Item auswählen',
  favourites: {
    showFavourite: 'Favoriten anzeigen',
    progressMessage: {
      pending: {
        add: 'Ihr Favorit wird hinzugefügt...',
        update: 'Aktualisieren Ihres Favoriten...',
        remove: 'Ihr Favorit wird entfernt...',
      },
      success: {
        add: 'Favorit hinzugefügt',
        update: 'Favorit aktualisiert',
        remove: 'Favorit entfernt',
      },
      error: 'Favorit konnte nicht aktualisiert werden',
    },
    dashboard: {
      title: 'Ihre Favoriten',
      products: {
        title: 'Produkte',
        showAll: 'Alle Produkte anzeigen',
        default:
          'Noch keine Favoriten. Erstellen Sie Favoriten, indem Sie in der <a class="text-primary underline cursor-pointer" href="{link}">Produktübersicht</a> auf den Stern klicken.',
      },
      riskAssessment: {
        title: 'Risikobewertungen',
        showAll: 'All Risikobewertungen anzeigen',
        default:
          'Noch keine Favoriten vorhanden. Erstellen Sie Favoriten, indem Sie in der Übersicht der <a class="text-primary underline cursor-pointer" href="{link}">Risikobewertungen</a> auf den Stern klicken.',
      },
      hazards: {
        title: 'Gefahren',
        showAll: 'Alle Gefahren anzeigen',
        default:
          'Noch keine Favoriten vorhanden. Erstellen Sie Favoriten, indem Sie in der Übersicht <a class="text-primary underline cursor-pointer" href="{link}">unerwünschte Substanzen</a> auf den Stern klicken.',
      },
      processingAids: {
        title: 'Verarbeitungshilfsstoffe',
        showAll: 'Alle Verarbeitungshilfsstoffe anzeigen',
        default:
          'Noch keine Favoriten vorhanden. Erstellen Sie Favoriten, indem Sie in der Übersicht der <a class="text-primary underline cursor-pointer" href="{link}">Verarbeitungshilfen</a> auf den Stern klicken.',
      },
      showAll: 'Alle anzeigen',
      showLess: 'Weniger anzeigen',
    },
  },
}
