<script setup lang="ts">
  const showDropdownMenu = ref(false)
  const target = ref()
  onClickOutside(target, () => {
    showDropdownMenu.value = false
  })
</script>

<template>
  <div class="flex flex-col items-center">
    <BasicBtn
      ref="target"
      color="icon"
      size="icon"
      @click="() => (showDropdownMenu = !showDropdownMenu)"
    >
      <img src="/icons/more_vert_24px.svg" class="h-6 w-6 min-w-6" />
    </BasicBtn>
    <BasicDropdownMenu class="absolute" v-show="showDropdownMenu" :fullwidth="true">
      <template #content>
        <slot />
      </template>
    </BasicDropdownMenu>
  </div>
</template>

<style scoped></style>
